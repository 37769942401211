import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import GamificationData from 'services/dataService/GamificationData';
import { useStyles } from '../styles';
import AssessmentIcon from '@material-ui/icons/Assessment';
import { Box, Grid, TextField, Typography, Button, Dialog } from '@material-ui/core';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import TimelineIcon from '@material-ui/icons/Timeline';
import { IoIosCheckmarkCircleOutline, IoIosCloseCircleOutline } from "react-icons/io";
import palette from 'theme/palette';
import { FilesData } from 'services/dataService';
import Extrato from './Extrato';

const Score = () => {
    const classes = useStyles();
    const [activeTab, setActiveTab] = useState(1);
    const [feedbackMessage, setFeedbackMessage] = useState("");
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const [selectedReward, setSelectedReward] = useState(null);
    const [rewards, setRewards] = useState([]);
    const [feedbackType, setFeedbackType] = useState(""); // "success" ou "error"
    const [rewardImages, setRewardImages] = useState({}); 
    const { id_company, id } = useSelector((state) => state.auth);
    const [coinName, setCoinName] = React.useState();

    useEffect(() => {
        const timeout = setTimeout(() => {
            setFeedbackMessage("");
        }, 2000);
        return () => clearTimeout(timeout);
    }, [feedbackMessage]);

    const openRescueDialog = (reward) => {
        setSelectedReward(reward);
        setOpenConfirmationDialog(true);
    };
    
    const closeRescueDialog = () => {
        setSelectedReward(null);
        setOpenConfirmationDialog(false);
    };
    

      async function getGamification(id) {
        const gamificationData = await GamificationData.getGamificationByIdCompany({ "id_company": id_company });
        const { data } = gamificationData;
        data.map((gamification, index) => {
          setCoinName(gamification.coin_name);
        })
      }

    const getTotalCoins = async (idUser) => {
        const data = await GamificationData.getTotalCoinsByUserId({ id_user: idUser });
        const { data: { total } } = data;

        return total;
    }

    const confirmRescue = async () => {
        if (selectedReward) {
            await handleRescue({
                id_reward: selectedReward.id,
                total_cost: selectedReward.cost,
                quantity: 1,
                data_rescue: new Date(),
            });
            closeRescueDialog();
        }
    };
    

    async function handleRescue({ id_reward, total_cost, quantity, data_rescue }) {
        try {
            const total_coins = await getTotalCoins(id);
    
            if (total_coins < total_cost) {
                setFeedbackType("error"); // Tipo de mensagem: erro
                setFeedbackMessage("Saldo insuficiente");
                return;
            }
    
            const selectedReward = await GamificationData.getRewardById({ id: id_reward });
    
            const rescue = await GamificationData.createRescue({
                id_user: id,
                id_rewards: id_reward,
                total_cost: total_cost,
                quantity: quantity,
                data_rescue: data_rescue,
                status_rescue: "Processado",
            });
    
            const { data: { userRewardsResponse } } = rescue;
    
            if (userRewardsResponse.id) {
                await GamificationData.createTransaction({
                    type: "DEBIT",
                    amount: userRewardsResponse.total_cost,
                    observations: "Resgate recompensa",
                    id_user: id,
                });
    
                setFeedbackType("success"); // Tipo de mensagem: sucesso
                setFeedbackMessage("Recompensa resgatada com sucesso");
                setTimeout(() => {
                    window.location.reload();
                }, 2500);
            }
        } catch (error) {
            console.error("Erro ao resgatar recompensa:", error);
        }
    }
    

    const getAllRewards = async () => {
        try {
            const { data: { allRewards } } = await GamificationData.getAllRewards({ id_company });
            setRewards(allRewards);

            const images = await Promise.all(
                allRewards.map(async (reward) => {
                    if (reward.id_file) {
                        const file = await FilesData.getFile(reward.id_file);
                        return { id: reward.id, url: file.data?.url || '' };
                    }
                    return { id: reward.id, url: '' };
                })
            );

            const imageMap = images.reduce((acc, image) => {
                acc[image.id] = image.url;
                return acc;
            }, {});
            setRewardImages(imageMap);
        } catch (error) {
            console.error("Erro ao carregar recompensas:", error);
        }
    };

    useEffect(() => {
        getGamification();
        getAllRewards();
    }, []);

    return (
        <div className={classes.scoreViewContainer}>
            <div className={classes.scoreViewHeader}>
                <div className={classes.scoreHeaderButtonContainer} onClick={() => setActiveTab(1)}>
                    <LocalOfferIcon />
                    <a href='#'>Recompensas</a>
                </div>
                <div className={classes.scoreHeaderButtonContainer} onClick={() => setActiveTab(2)}>
                    <AssessmentIcon />
                    <a href='#'>Extrato</a>
                </div>
                {/* <div className={classes.scoreHeaderButtonContainer} onClick={() => setActiveTab(3)}>
                    <TimelineIcon />
                    <a href='#'>Ranking</a>
                </div> */}
            </div>
            <div className={classes.scoreViewContent}>
                {
                     activeTab === 1 && (
                        <div>
                            {feedbackMessage && (
                                <Dialog
                                    open={Boolean(feedbackMessage)}
                                    fullWidth
                                    maxWidth="md"
                                >
                                    <div className={classes.feedbackMessageRewardsContainer}>
                                         {feedbackType === "success" ? (
                                            <IoIosCheckmarkCircleOutline size={80} color={palette.primary.main} />
                                                ) : (
                                            <IoIosCloseCircleOutline size={80} color={palette.error.main} /> // Ícone para saldo insuficiente
                                        )}                                     
                                    <Typography variant='h4'>{feedbackMessage}</Typography>
                                    </div>
                                </Dialog>
                            )}
                            <div className={classes.rewardTitle}>
                                <Typography variant="h4">Vitrine de Recompensas</Typography>
                            </div>
                            <Grid container spacing={2} item md={12} xs={12} >
                                {rewards.length > 0 && rewards.map((reward) => (
                                    <Grid item xs={12} sm={6} md={3} key={reward.id}>
                                        <div className={classes.rewardBox}>
                                            <div
                                                className={classes.rewardBoxImage}
                                                style={{
                                                    backgroundImage: `url(${rewardImages[reward.id] || ''})`,
                                                    backgroundSize: 'cover',
                                                    backgroundPosition: 'center',
                                                    height: 150,
                                                    borderBottom: 8,
                                                }}
                                            />
                                            <div className={classes.rewardBoxContent}>
                                                <Typography variant="h5" style={{fontWeight: 'bold'}}>{reward.name}</Typography>
                                                <Typography variant="body2">{reward.description}</Typography>
                                                <div className={classes.rewardBoxContentBottom}>
                                                    <Typography variant="h6" className={classes.rewardBoxCoins}>
                                                    <img
                                                        className='coinImage' src={"/images/Coin/coin.png"}
                                                        alt="Minha Imagem"
                                                        style={{ width: '10%', height: '10%', marginBottom: '2px' }} 
                                                    />
                                                        {reward.cost} {coinName && coinName}
                                                    </Typography>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        className={classes.rewardBoxButton}
                                                        onClick={() => openRescueDialog(reward)}
                                                    >
                                                        Resgatar
                                                    </Button>
                                                    <Dialog
                                                        open={openConfirmationDialog}
                                                        fullWidth
                                                        scroll="body"
                                                        onClose={closeRescueDialog}
                                                        maxWidth="sm"
                                                    >
                                                        <div className={classes.dialogContent}>
                                                            <Typography variant="h6">
                                                                Você deseja realmente resgatar a recompensa "{selectedReward?.name}" por {selectedReward?.cost} {coinName}?
                                                            </Typography>
                                                            <Box display="flex" justifyContent="flex-end" marginTop={2}>
                                                                <Button onClick={closeRescueDialog} color="secondary">
                                                                    Cancelar
                                                                </Button>
                                                                <Button onClick={confirmRescue} color="primary" variant="contained" style={{ marginLeft: '10px' }}>
                                                                    Sim
                                                                </Button>
                                                            </Box>
                                                        </div>
                                                    </Dialog>
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                ))}
                            </Grid>
                        </div>
                    )
                }
                {activeTab === 2 && (
                    <div>
                        <div>
                            <h4>Extrato</h4>
                        </div>
                        <div>
                            <Extrato />
                        </div>
                    </div>
                )}
                {activeTab === 3 && (
                    <div>
                        <div>
                            <h4>Ranking</h4>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Score;
