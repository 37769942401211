import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import MaterialTable, { MTableToolbar } from 'material-table';
import {
    Grid,
    Button,
    TextField,
    CardContent
} from '@material-ui/core';

import { useStyles } from '../../styles';
import { Search } from '@material-ui/icons';
import { localizationTable, options } from 'constants/table';
import GamificationData from 'services/dataService/GamificationData';
import { useSelector } from 'react-redux';
import { FilesData } from 'services/dataService';

export default () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalType, setModalType] = useState(null);
    const [name, setName] = useState("");
    const [stock, setStock] = useState(null);
    const [idFile, setIdFile] = useState(null);
    const [filter, setFilter] = useState('');
    const [url, setUrl] = useState(null);
    const [cost, setCost] = useState(null);
    const [description, setDescription] = useState("");
    const [feedbackMessage, setFeedbackMessage] = useState(null);
    const [selectedRewardId, setSelectedRewardId] = useState(null);
    const [photo, setPhoto] = useState(null);

    const tableRef = useRef();
    const classes = useStyles();
    const { register, handleSubmit, control } = useForm();


    const { t } = useTranslation([
        'label',
        'toastify',
        'tables',
        'form',
        'button',
        'validation',
    ]);

    const handleSubmitFilter = (data) => {
        setFilter(data.filter);
    
        tableRef.current.state.query.page = 0;
        tableRef.current.onQueryChange();
      };

    const { id, id_company } = useSelector(
        (state) => state.auth
    );

    async function handleAddReward(event, data) {
        event.preventDefault();

        const { name, cost, stock, description, id_company } = data;

        const responseFile = await FilesData.addFile(photo, 'Reward');

        const rewardResponse = await GamificationData.createReward({
            name: name,
            cost: cost,
            stock: stock,
            description: description,
            id_company: id_company,
            id_file: responseFile.data.id
        })

        if (rewardResponse.status === 200) {
            setIsModalOpen(!isModalOpen);
            setFeedbackMessage("Recompensa adicionada com sucesso");
            setTimeout(() => {
                setFeedbackMessage(null);
            }, 2000)
            tableRef.current && tableRef.current.onQueryChange(); // Atualiza a tabela após a edição
        }

        if (rewardResponse.status !== 200) {
            setFeedbackMessage("Erro ao adicionar recompensa");
            setTimeout(() => {
                setFeedbackMessage(null);
            }, 2000)
            tableRef.current && tableRef.current.onQueryChange(); // Atualiza a tabela após a edição
        }

    }

    async function handleEditReward(event, data) {
        event.preventDefault();
        let responseFile = null;

        if (photo) {
            responseFile = await FilesData.addFile(photo, 'Reward');
            console.log('responseFile!', responseFile);
        } else {
            console.warn("Nenhuma foto foi fornecida para o upload.");
        }

        try {
            const rewardResponse = await GamificationData.updateReward({
                id_reward: selectedRewardId,
                data: {
                    name: data.name,
                    cost: data.cost,
                    stock: data.stock,
                    description: data.description,
                    id_company: id_company,
                    id_file: responseFile?.data?.id ? responseFile?.data?.id  : idFile 
                }
            });

            if (rewardResponse.status === 200) {
                setIsModalOpen(!isModalOpen);
                setFeedbackMessage("Recompensa editada com sucesso");
                setTimeout(() => {
                    setFeedbackMessage(null);
                    // window.location.reload();
                }, 2000)
                tableRef.current && tableRef.current.onQueryChange(); 
            } else {
                setFeedbackMessage("Erro ao editar recompensa");
            }
        } catch (error) {
            console.error(error);
            toast.error("Erro ao editar recompensa.");
            setTimeout(() => {
                setFeedbackMessage(null);
            }, 2000)
        }

    }

    async function handleDeleteReward(event, idReward) {
        event.preventDefault();
        try {
            const deletedReward = await GamificationData.deleteReward({ id: idReward });

            if (deletedReward.status === 204) {
                setIsModalOpen(!isModalOpen);
                setFeedbackMessage("Recompensa excluída com sucesso!");
                setTimeout(() => {
                    setFeedbackMessage(null);
                }, 2000);     
                tableRef.current && tableRef.current.onQueryChange(); 

            }
        } catch (error) {
            console.error(error);
            if (error.response && error.response.data && error.response.data.error) {
                const errorMessage = error.response.data.error;
                if (errorMessage.includes('associated with user rewards')) {
                    toast.error("Este produto possui itens resgatados e não pode ser excluído.");
                } else {
                    toast.error(errorMessage); 
                }
            } else {
                toast.error("Erro ao deletar recompensa.");
            }
        }
    }

    return (
        <div className='notranslate'>
            {
                feedbackMessage && (
                    <div className={classes.modal}>
                        <div className={classes.feedbackMessageContainer}>
                            <p>{feedbackMessage}</p>
                        </div>
                    </div>
                )
            }
            {
                isModalOpen && (
                    <>
                        <div className={classes.modal}>
                            {
                                modalType === "add" && (
                                    <div className={classes.modalContent}>
                                        <div className={classes.modalHeader}>
                                            <h1 className={classes.modalHeaderTitle}>Adicionar Recompensa</h1>
                                            <div className={classes.modalClose}>
                                                <a onClick={() => setIsModalOpen(!isModalOpen)}>
                                                    <span className={classes.modalCloseIcon}>✖</span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className={classes.modalRewardContainer}>
                                            <form className={classes.modalForm} onSubmit={(event) => handleAddReward(event, { name, cost, stock, description, id_company })}>
                                                <input type='text' onChange={(e) => setName(e.target.value)} className={classes.modalFormInput} placeholder='Nome' />
                                                <input type='number' onChange={(e) => setStock(e.target.value)} className={classes.modalFormInput} placeholder='Estoque' />
                                                <input type='number' onChange={(e) => setCost(e.target.value)} className={classes.modalFormInput} placeholder='Custo' />
                                                <textarea type='text' onChange={(e) => setDescription(e.target.value)} className={classes.modalFormInput} placeholder='Descrição' />
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={(e) => setPhoto(e.target.files[0])}
                                                    className={classes.modalFormInput}
                                                />
                                                 <span style={{fontSize: '12px'}}>Tamanho sugerido de imagem: 500x281</span>
                                            
                                                <button type='submit' className={classes.modalFormButton}>Adicionar</button>
                                            </form>
                                        </div>
                                    </div>
                                )
                            }
                            {
                                modalType === "edit" && (
                                    <div className={classes.modalContent}>
                                        <div className={classes.modalHeader}>
                                            <h1 className={classes.modalHeaderTitle}>Editar Recompensa</h1>
                                            <div className={classes.modalClose}>
                                                <a onClick={() => setIsModalOpen(!isModalOpen)}>
                                                    <span className={classes.modalCloseIcon}>✖</span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className={classes.modalRewardContainer}>
                                            <form className={classes.modalForm} onSubmit={(event) => handleEditReward(event, { name, cost, stock, description, id_company })}>
                                                <input type='text' value={name} onChange={(e) => setName(e.target.value)} className={classes.modalFormInput} placeholder='Nome' />
                                                <input type='number' value={stock} onChange={(e) => setStock(e.target.value)} className={classes.modalFormInput} placeholder='Stock' />
                                                <input type='number' value={cost} onChange={(e) => setCost(e.target.value)} className={classes.modalFormInput} placeholder='Custo' />
                                                <textarea type='text' value={description} onChange={(e) => setDescription(e.target.value)} className={classes.modalFormInput} placeholder='Descrição' />
                                                <div style={{ marginBottom: '10px', width: '100%', height: '200px', overflow: 'hidden', borderRadius: '8px' }}>
                                                    <img 
                                                        src={url} 
                                                        alt={'no-image'} 
                                                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                                    />
                                                </div>
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={(e) => setPhoto(e.target.files[0])}
                                                    className={classes.modalFormInput}
                                                />
                                                <span style={{fontSize: '12px'}}>Tamanho sugerido de imagem: 500x281</span>

                                                <button type='submit' className={classes.modalFormButton}>SALVAR</button>
                                            </form>
                                        </div>
                                    </div>
                                )
                            }
                            {
                                modalType === "delete" && (
                                    <div className={classes.modalContent}>
                                        <div className={classes.modalHeader}>
                                            <h1 className={classes.modalDeleteHeaderTitle}>Remover Recompensa</h1>
                                            <div className={classes.modalClose}>
                                                <a onClick={() => setIsModalOpen(!isModalOpen)}>
                                                    <span className={classes.modalCloseIcon}>✖</span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className={classes.modalDeleteContainer}>
                                            <p>Deseja apagar a recompensa <strong>{name}</strong>?</p>
                                            <div className={classes.modalRemoveRewardAction}>
                                                <a className={classes.modalRemoveRewardActionButtonActive} onClick={(event) => handleDeleteReward(event, selectedRewardId)}>Sim</a>
                                                <a className={classes.modalRemoveRewardActionButtonInactive} onClick={() => setIsModalOpen(!isModalOpen)}>Não</a>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </>
                )
            }
            <Grid container spacing={3} style={{ marginTop: 20 }}>
                <Grid
                    item
                    className={classes.actions}>
                    <Button
                        color="primary"
                        variant="contained"
                        size="small"
                        onClick={() => {
                            setIsModalOpen(!isModalOpen);
                            setModalType('add');
                        }}
                    >
                        Adicionar Recompensa
                    </Button>
                </Grid>
            </Grid>
            <MaterialTable
                style={{ marginTop: 30 }}
                title={`${t('Recompensas')}`}
                tableRef={tableRef}
                columns={[
                    {
                        title: `${t('Nome')}`,
                        field: `name`,
                        sorting: false,
                        render: (rowData) => rowData.name,
                    },
                    {
                        title: `${t('Custo')}`,
                        sorting: false,
                        field: 'cost',
                        render: (rowData) => rowData.cost,
                    },
                    {
                        title: `${t('Estoque')}`,
                        field: 'stock',
                        sorting: false,
                        render: (rowData) => rowData.stock,
                    },
                    {
                        title: `${t('Descrição')}`,
                        field: 'description',
                        sorting: false,
                        render: (rowData) =>
                            rowData.description,
                    },
                ]}
                data={async (query) => {
                    try {
                        const rewardsResponse = await GamificationData.getAllRewards({
                            id_company,
                            page: query.page + 1,
                            limit: query.pageSize,
                            filter,
                        });
                        const { data: { allRewards } } = rewardsResponse;
                        setIsLoading(false);

                        return {
                            data: allRewards,
                            page: rewardsResponse.data.currentPage - 1,
                            totalCount: rewardsResponse.data.totalCount,
                        };
                    } catch (err) {
                        if (err.response?.status === 403) {
                            toast.error(t('toastify:commons.toast_error_403'));
                        } else {
                            toast.error(t('toastify:commons.toast_error_api'));
                        }
                        setIsLoading(false);

                        return { data: [] };
                    }
                }}
                components={{
                    search: true,
                    title: false,
                    Toolbar: (props) => (
                        <div>
                          <MTableToolbar {...props} />
                          <form onSubmit={handleSubmit(handleSubmitFilter)}>
                            <CardContent>
                              <Grid container spacing={3}>
                                <Grid item md={8} xs={12}>
                                  <TextField
                                    fullWidth
                                    label={t('form:commons.input_search')}
                                    name="filter"
                                    type="text"
                                    variant="outlined"
                                    defaultValue={filter || ''}
                                    inputRef={register}
                                  />
                                </Grid>
                                <Grid item md={4} xs={12} className={classes.actions}>
                                  <Button
                                    color="primary"
                                    variant="contained"
                                    size="small"
                                    type="submit"
                                  >
                                    <Search style={{ marginRight: '5px' }} />
                                    {t('button:commons.btn_advanced_filter')}
                                  </Button>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </form>
                        </div>
                      ),
                    }}
                actions={[
                    {
                        icon: 'edit',
                        tooltip: `Editar`,
                        position: 'row',
                        onClick: (event, rowData) => {
                            event.preventDefault();
                            setIsModalOpen(!isModalOpen);
                            setModalType("edit");
                            setName(rowData.name);
                            setCost(rowData.cost);
                            setStock(rowData.stock);
                            setDescription(rowData.description);
                            setIdFile(rowData.id_file);
                            setUrl(rowData.file?.url);
                            rowData.id && setSelectedRewardId(rowData.id);
                        },
                        iconProps: { color: 'primary' },
                    },
                    {
                        icon: 'delete',
                        tooltip: `Remover`,
                        position: 'row',
                        onClick: (event, rowData) => {
                            event.preventDefault();
                            setIsModalOpen(!isModalOpen);
                            setModalType("delete");
                            setName(rowData.name);
                            setCost(rowData.cost);
                            setStock(rowData.stock);
                            setDescription(rowData.description);
                            rowData.id && setSelectedRewardId(rowData.id);
                        },
                        iconProps: { color: 'primary' },
                    },
                ]}
                isLoading={isLoading}
                localization={localizationTable(t)}
                options={options}
            />
        </div>

    );
};
