import React, { useState, useEffect } from 'react';
import GamificationData from 'services/dataService/GamificationData';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

// import { Box, Menu, TextField, Typography } from '@material-ui/core';

const useStyles = makeStyles({
    container: {
        padding: '20px 5px',
        display: 'flex',
        alignItems: 'center',
    },
    table: {
        width: '100%',
        maxWidth: '800px',
        borderCollapse: 'collapse',
        borderRadius: '8px',
        overflow: 'hidden',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    },
    thead: {
        backgroundColor: '#176AE6',
        color: '#fff',
    },
    th: {
        padding: '12px 20px',
        textAlign: 'center',
        fontWeight: 'bold',
    },
    tbody: {
        '& tr:nth-child(even)': {
            backgroundColor: '#f9f9f9',
        },
        '& tr:hover': {
            backgroundColor: '#f1f1f1',
        },
    },
    td: {
        padding: '12px 20px',
        textAlign: 'center',
        fontSize: '16px',
        color: '#333',
    },
    rank: {
        fontWeight: 'bold',
        color: '#333',
    },
    score: {
        fontWeight: 'bold',
        color: '#176AE6',
    },
});

export default () => {
    const classes = useStyles();
    const [transactionsData, setTransactionsData] = useState([]);

    const { id } = useSelector(
        (state) => state.auth
    );

    async function getData() {
        const transactionsResponse = await GamificationData.getAllTransactions({
            id_user: id
        });
        console.log('transactionsResponse > ', transactionsResponse);
        setTransactionsData(transactionsResponse.data);
    }

    function formatDateTime(isoDateString) {
        const date = new Date(isoDateString);

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${day}/${month}/${year} - ${hours}:${minutes}:${seconds}`;
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <>
            <div className={classes.container}>
                <table className={classes.table}>
                    <thead className={classes.thead}>
                        <tr>
                            <th className={classes.th}>Operação</th>
                            <th className={classes.th}>Data</th>
                            <th className={classes.th}>Valor</th>
                            <th className={classes.th}>Descrição</th>


                        </tr>
                    </thead>
                    <tbody className={classes.tbody}>
                        {transactionsData.map((transaction, index) => (
                            <tr key={transaction.id}>
                                <td className={classes.td}>{transaction.type === 'CREDIT' ? "🟢" : "🔴"}</td>
                                <td style={{textAlign: "center"}}>{formatDateTime(transaction.createdAt)}</td>
                                <td className={classes.td}>{transaction.amount}</td>
                                <td className={classes.td}>{transaction.observations}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
};
